import axios from "axios";

/**
 * 获取所有的国家数据
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getNationality = () => axios.post("/common/getNationality");

/**
 * 获取所有的民族数据
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getRaceList = () => axios.post("/common/getRaceList");

/**
 * 获取所有的省份信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getProvinces = () => axios.post("/common/getProvinces");


/**
 * 获取某个省下的城市数据
 * @param parentCode
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getCities = (parentCode) => axios.post("/common/getCities", {parentCode: parentCode})

/**
 * 获取某个城市下的区信息
 * @param parentCode
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getDistricts = (parentCode) => axios.post("/common/getDistricts", {parentCode: parentCode});

/**
 * 上传文件的接口路径
 * @returns {string}
 */
export const getUploadUrl = () => "/common/uploadFile";

/**
 * 提交意见和建议
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const saveSuggestion = (params) => axios.post("/common/saveSuggestion", params);
