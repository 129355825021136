import axios from "axios";

/**
 * 获取个人基本信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getUserinfo = () => axios.post("/run/center/getUserinfo")


/**
 * 修改个人基本信息
 * @param param
 * @returns {Promise<AxiosResponse<T>>}
 */
export const saveUserinfo = (param) => axios.post("/run/center/saveUserinfo", param);
